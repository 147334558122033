import { useState, useContext, useEffect } from 'react';
import { LanguageToggle } from '../../components/elements/LanguageToggle';
import { ExpandableTriangle } from '../../components/layouts/ExpandableTriangle';
import { Header } from '../../components/layouts/Header';
import { EditArticle } from '../../features/articleManagement/EditArticle';
import Button from '../../components/elements/Button';
import { useParams } from 'react-router-dom';
import { useModal } from '../../app/context/PopUpModalContext';
import { Modal } from '../../components/layouts/PopUpModal';
import { ErrorLottie } from '../../components/animations/ErrorLottie';
import { useTheme } from 'styled-components';
import { AuthContext } from '../../app/context/AuthContext';
import translationsStatus from '../../translations/status';
import { Button as PopupButton } from '../../components/layouts/PopUpModal/Style';
import { Select } from '../../features/articleManagement/EditArticle/MetaPanel/Categories/CategoryManager/Style';
import translationsPublish from '../../translations/publish';
import { useArticleContext } from '../../app/context/ArticleContext';
import ApiHttpClient from '../../utils/api/api-http-client';
import { ArticleLink } from '../../components/articleLink';
import ButtonSpinner from '../../components/elements/Button/ButtonSpinner';

export const EditArticlePage = () => {
  const theme = useTheme();
  const { id } = useParams();

  const [languageArticle, setLanguageArticle] = useState('fr');
  const [viewModal, setViewModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [englishStatus, setEnglishStatus] = useState();
  const [frenchStatus, setFrenchStatus] = useState();
  const { appLocale } = useContext(AuthContext)?.user || {};
  const [hideButton, setHideButton] = useState(true);
  const [onClose, setOnClose] = useState();
  const { currentArticle } = useArticleContext();
  const [publishedStatus, setPublishedStatus] = useState('');
  const apiClient = new ApiHttpClient();

  useEffect(() => {
    if (appLocale) {
      getPublishedStatus();
    }
  }, [languageArticle, currentArticle, appLocale]);

  const { setModalContent } = useModal();

  const getOptions = () => {
    const options = [];
    for (const [key, value] of Object.entries(translationsPublish[appLocale])) {
      options.push({ value: key, label: value });
    }
    return options;
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {}, [loading]);

  const publish = async (id, lg) => {
    try {
      setLoading(true);
      setModalContent(
        <>
          <h2>{appLocale === 'en' ? 'Publish Article' : "Publier l'article"}</h2>
          <Select
            defaultValue={getOptions()[0]}
            options={getOptions()}
            onChange={(e) => {
              lg = e.value;
            }}
            menuPlacement="auto"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginTop: theme.spacing.md,
            }}
          >
            <PopupButton
              style={{
                margin: 0,
                width: '45%',
                backgroundColor: theme.colors.warning,
              }}
              disabled={true}
            >
              {appLocale === 'en' ? 'Cancel' : 'Annuler'}
            </PopupButton>
            <PopupButton style={{ margin: 0, width: '45%', marginLeft: 'auto' }} disabled={loading}>
              <ButtonSpinner />
            </PopupButton>
          </div>
        </>,
      );
      const languages = [];
      if (lg === 'FR' || lg === 'BOTH') {
        languages.push('fr');
      }
      if (lg === 'EN' || lg === 'BOTH') {
        languages.push('en');
      }
      const res = await apiClient.put(`/articles/${id}/publish`, { languages });

      if (res.data.success) {
        setOnClose(() => window.location.reload());
      } else {
        throw new Error('Publishing failed');
      }
    } catch (error) {
      setHideButton(false);
      setModalContent(
        <>
          <ErrorLottie /> {appLocale === 'en' ? 'Failed to publish the article' : 'Échec de publication'}
        </>,
      );
    } finally {
      setLoading(false);
    }
  };

  const publishArticle = (id) => {
    let lg = 'FR';
    setHideButton(true);
    setOnClose(() => {
      setHideButton(true);
    });
    setModalContent(
      <>
        <h2>{appLocale === 'en' ? 'Publish Article' : "Publier l'article"}</h2>
        <Select
          defaultValue={getOptions()[0]}
          options={getOptions()}
          onChange={(e) => {
            lg = e.value;
          }}
          menuPlacement="auto"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: theme.spacing.md,
          }}
        >
          <PopupButton
            style={{
              margin: 0,
              width: '45%',
              backgroundColor: theme.colors.warning,
            }}
            onClick={() => setModalContent(null)}
          >
            {appLocale === 'en' ? 'Cancel' : 'Annuler'}
          </PopupButton>
          <PopupButton style={{ margin: 0, width: '45%', marginLeft: 'auto' }} onClick={() => publish(id, lg)}>
            {appLocale === 'en' ? 'Publish' : 'Publier'}
          </PopupButton>
        </div>
      </>,
    );
  };

  const getStatus = () => {
    if (!appLocale) return;
    return translationsStatus[appLocale][languageArticle === 'en' ? englishStatus : frenchStatus];
  };

  const getPublishedStatus = () => {
    const translation = currentArticle?.translations.find((t) => t.locale === languageArticle);
    if (translation?.publishDate) {
      let publishedOn = appLocale === 'en' ? 'Last version published: ' : 'Dernière version publiée: ';
      publishedOn += new Date(translation.publishDate).toISOString().split('T')[0];
      setPublishedStatus(publishedOn);
    } else {
      setPublishedStatus(appLocale === 'en' ? 'Not published' : 'Non publié');
    }
  };

  const translation = currentArticle?.translations.find((t) => t.locale === languageArticle);

  return (
    <>
      <Header>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LanguageToggle languageArticle={languageArticle} setLanguageArticle={setLanguageArticle} />
          {/*<div style={{ display: 'flex', alignItems: 'center'}} onClick={() => setPreviewModal((prevValue) => !prevValue)}>*/}
          {/*  {previewModal ? (*/}
          {/*    <i className='fa-solid fa-right-from-bracket'  style={{ fontSize: '2.35rem', margin: 0, marginLeft: '2rem', color: 'rgb(23, 92, 211)', cursor: 'pointer' }}></i>*/}
          {/*  ) : (*/}
          {/*    <i className='fa-solid fa-mobile-notch' style={{ fontSize: '2.35rem', margin: 0, marginLeft: '2rem', color: 'rgb(23, 92, 211)', cursor: 'pointer' }}>*/}
          {/*    </i>*/}
          {/*  )}*/}
          {/*</div>*/}
          <div
            style={{
              marginLeft: '2rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontSize: '12px',
                fontWeight: theme.typography.fontWeight.bold,
                color: theme.colors.primary,
              }}
            >
              {getStatus()}
            </span>
          </div>
        </div>
        <div style={{ marginLeft: 'auto', marginRight: '2rem' }}>
          <ArticleLink translation={translation} locale={languageArticle} />
        </div>
        <div>
          <span
            style={{
              marginRight: '2rem',
              fontSize: '12px',
              fontWeight: theme.typography.fontWeight.bold,
              color: theme.colors.primary,
            }}
          >
            {publishedStatus}
          </span>
        </div>
        <div>
          {appLocale && (
            <Button onClick={() => publishArticle(id)}>
              {appLocale === 'en' ? 'Publish' : 'Publier'} &nbsp;<i class="fa-solid fa-file-circle-check"></i>
            </Button>
          )}
          <ExpandableTriangle onClick={() => setViewModal(true)} />
        </div>
      </Header>
      <Modal hideButton={hideButton} onClose={onClose} />
      <EditArticle
        viewModal={viewModal}
        setViewModal={setViewModal}
        previewModal={previewModal}
        setPreviewModal={setPreviewModal}
        languageArticle={languageArticle}
        setFrenchStatus={setFrenchStatus}
        setEnglishStatus={setEnglishStatus}
      />
    </>
  );
};
